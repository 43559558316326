import produce from "immer";

export const GET_SETTINGS = "GET_SETTINGS";


export interface ISettings {
    pod_start: number | null,
    pod_end: number | null,
    pod_values: number[]
}

const initialState: ISettings = {
    pod_start: null,
    pod_end: null,
    pod_values: []
};

export default (state = initialState, action): ISettings =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_SETTINGS:
                draft.pod_start = action.payload.pod_start;
                draft.pod_end = action.payload.pod_end;
                break;

            default:
                break;
        }
    });



