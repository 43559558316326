import React, { useState, useEffect } from "react";
import "./PocDetails.scss";
import { Link, useParams } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import { IPoc } from "src/state/pocs/reducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getPocs } from "src/state/pocs/actions";
import { getPocDetails } from "src/api/poc";
import { getUsersbyId } from "src/api/users";
import { mapAdminPocs, mapPocDetails, mapPOCDetailsbyId } from "src/helpers/pocs";
import { formatDate } from "../PocsColumns";
import { capitalizeFirstLetter } from "src/helpers/common";


const poc = {
    pocId: -1,
    createdOn: '',
    updatedOn: "",
    labProviderType: "",
    status: "",
    company: "",
    accountAe: "",
    oppId: "",
    requestedStart: null,
    actualStart: null,
    endDate: null,
    podNumber: -1,
    testPlan: null,
    theatre: "",
    stage: "",
    deployedBy: "",
    accountSe: "",
    assignedUsers: [],
    assignedGroups: [],
    assignedCompanies: [],
    vcForUsers: [],
    vcForGroups: [],
    vcForCompanies: [],
    se_user: {
        id: "",
        email: "",
        name: "",
        company: null,
        type: "",
        userType: -1,
        role: "",
        userRole: -1,
        isActive: null,
        isInvited: null,
        labsLaunched: -1,
        lastLogin: "",
        status: ""
    }
}


const PocDetails: React.FC<any> = () => {

    const [pocData, setPocData] = useState(poc)
    const pocs: any = useSelector((state: RootState) => state.pocs.list);
    const userList: Array<any> = useSelector((state: RootState) => (state.users.list));

    const { pocId }: { pocId } = useParams();


    useEffect(() => {
        if (pocs.length === 0) {
            const fetchPocbyId = async () => {
                let pocData = await getPocDetails(pocId);
                pocData = mapPOCDetailsbyId(pocData);
                pocData.se_user = await getUsersbyId(pocData.accountSe)
                setPocData(pocData)
            }
            fetchPocbyId()
        }
        else {
            let pocData = pocs.find((item) => item.pocId == pocId);
            let userData = userList.find((item) => item.id == pocData?.accountSe);
            pocData = { ...pocData, se_user: userData }
            setPocData(pocData)
        }
    }, [])

    return <>
        <div className="px-4 tabs-layout">
            <div className={`pocs-list-section-header mb-3`}>
                <b>
                    <Link className="group-user-header" to="/admin/pocs">
                        POCs
                    </Link>
                </b>
                <img
                    height="15"
                    width="15"
                    src={rightArrow}
                    alt="InfoIcon"
                    className="mr-1 ml-1"
                />
                <span className="h4">Hosted POC Details</span>
            </div>
            {/* <span className="mb-2 tabs-layout-header"><b> Hosted POC Details </b></span> */}
            <div className="details_container">
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Opportunity Info" >
                            <InfoCapsules name="Company" value={pocData.company} />
                            <InfoCapsules name="Opportunity ID" value={pocData.oppId} />
                            <InfoCapsules name="Account SE" value={pocData.se_user.name} />
                            <InfoCapsules name="Account AE" value={pocData.accountAe} />
                            <InfoCapsules name="Theatre" value={pocData.theatre} />
                            <InfoCapsules name="Stage" value={pocData.stage} />
                        </InfoDetails>
                    </div>
                    <div>
                        <InfoDetails lable="General Info" >
                            <InfoCapsules name="Created On" value={pocData.createdOn && formatDate(pocData.createdOn)} />
                            <InfoCapsules name="Status" value={capitalizeFirstLetter(pocData.status)} />
                            <InfoCapsules name="Requested Start" value={pocData.requestedStart && formatDate(pocData.requestedStart)} />
                            <InfoCapsules name="Actual Start" value="" />
                            <InfoCapsules name="Days Active" value="" />
                            <InfoCapsules name="Date Closed" value="" />
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3"> <InfoDetails lable="Deployed Console VMs"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Assigned Resources"> <InfoCapsules name="Assigned POD" value={pocData.podNumber} /></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Notes/Comments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Attachments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Activities"><div></div></InfoDetails></div>
            </div>
        </div>
    </>

}
export default PocDetails;

const InfoDetails = ({ lable, children }) => {
    return <>
        <label className='card_header'>{lable} </label>
        <ul className="details_tab">
            {children}
        </ul>
    </>
}
const InfoCapsules = ({ name, value }) => {
    return <>
        <li className="d-flex">
            <div>{name}</div>
            <div>{value}</div>
        </li>
    </>
}