import React from "react";
import usersActive from "src/assets/images/user-active.svg";
import usersInactive from "src/assets/images/user-inactive.svg";
import labActive from "src/assets/images/lab-active.svg";
import labInactive from "src/assets/images/lab-inactive.svg";
import pocActive from "src/assets/images/pocs-active.svg"
import pocInactive from "src/assets/images/pocs-inactive.svg"
import storiesActive from "src/assets/images/stories-active.svg";
import storiesInactive from "src/assets/images/stories-inactive.svg";
import presentationActive from "src/assets/images/presentation-active.svg";
import presentationInactive from "src/assets/images/presentation-inactive.svg";
import groupsActive from "src/assets/images/group-active.svg";
import groupsInactive from "src/assets/images/group-inactive.svg";
import companyActive from "src/assets/images/company-active.svg";
import companyInactive from "src/assets/images/company-inactive.svg";
import vouchersActive from "src/assets/images/voucher-active.svg";
import vouchersInactive from "src/assets/images/voucher-inactive.svg";
import { NavLink } from "react-router-dom";
import "./TopNavBar.scss";
import { RouteComponentProps } from "react-router";
import {
  ADMIN_USERS_ROUTE,
  ADMIN_LABS_ROUTE,
  ADMIN_POCS_ROUTE,
  ADMIN_COMPANY_ROUTE,
  ADMIN_GROUPS_ROUTE,
  STORYLINES_ROUTE,
  ADMIN_VOUCHERS_ROUTE,
  ADMIN_PRESENTATIONS_ROUTE,
  ADMIN_GROUP_USERS_ROUTE,
  ADMIN_DASHBOARD_ROUTE,
  NOTIFICATIONS_ROUTE,
} from "src/constants/appRoutes";
import { ReactComponent as DashboardIcon } from "src/assets/images/dashboard.svg";
import NotificationIcon from "src/assets/svgrImages/NotificationIcon";
import { cx } from "@emotion/css";

type TopNavBarProps = {} & RouteComponentProps;

const TopNavBar: React.FC<TopNavBarProps> = ({ match, ...props }) => {
  return (
    <div className="d-flex justify-content-center">
      <div className="topnav-container  d-flex justify-content-around">
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_DASHBOARD_ROUTE}`}
            className="d-flex itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            <DashboardIcon
              className={
                props.location.pathname.includes(
                  `${match.url}${ADMIN_DASHBOARD_ROUTE}`
                )
                  ? "topnav-icon topnav-icon-active"
                  : "topnav-icon topnav-icon-inactive"
              }
            />
            <div className="ml-2">Dashboard</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_LABS_ROUTE}`}
            className="d-flex itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname.includes(
              `${match.url}${ADMIN_LABS_ROUTE}`
            ) ? (
              <img src={labActive} alt={"labActive"} />
            ) : (
              <img src={labInactive} alt={"labInactive"} />
            )}
            <div className="ml-2">Labs</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_POCS_ROUTE}`}
            className="d-flex itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname.includes(
              `${match.url}${ADMIN_POCS_ROUTE}`
            ) ? (
              <img src={pocActive} alt={"pocActive"} />
            ) : (
              <img src={pocInactive} alt={"pocInactive"} />
            )}
            <div className="ml-2">Hosted POCs</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${STORYLINES_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname.includes(
              `${match.url}${STORYLINES_ROUTE}`
            ) ? (
              <img src={storiesActive} alt={"storiesActive"} />
            ) : (
              <img src={storiesInactive} alt={"usersInactive"} />
            )}
            <div className="ml-2">Guides</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_PRESENTATIONS_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname ===
              `${match.url}${ADMIN_PRESENTATIONS_ROUTE}` ? (
              <img src={presentationActive} alt={"presentationActive"} />
            ) : (
              <img src={presentationInactive} alt={"presentationInactive"} />
            )}
            <div className="ml-2">Presentations</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_USERS_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname === `${match.url}${ADMIN_USERS_ROUTE}` ? (
              <img src={usersActive} alt={"usersActive"} />
            ) : (
              <img src={usersInactive} alt={"usersInactive"} />
            )}
            <div className="ml-2">Users</div>
          </NavLink>
        </div>

        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_GROUPS_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname === `${match.url}${ADMIN_GROUPS_ROUTE}` ||
              props.location.pathname.includes("admin/groups") ? (
              <img src={groupsActive} alt={"groupsActive"} />
            ) : (
              <img src={groupsInactive} alt={"groupsInactive"} />
            )}
            <div className="ml-2">Groups</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_COMPANY_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname ===
              `${match.url}${ADMIN_COMPANY_ROUTE}` ? (
              <img src={companyActive} alt={"companyActive"} />
            ) : (
              <img src={companyInactive} alt={"companyInactive"} />
            )}
            <div className="ml-2">Companies</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${ADMIN_VOUCHERS_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            {props.location.pathname ===
              `${match.url}${ADMIN_VOUCHERS_ROUTE}` ? (
              <img src={vouchersActive} alt={"vouchersActive"} />
            ) : (
              <img src={vouchersInactive} alt={"vouchersInactive"} />
            )}
            <div className="ml-2">Vouchers</div>
          </NavLink>
        </div>
        <div className="item-container d-flex justify-content-center align-items-center">
          <NavLink
            to={`${match.url}${NOTIFICATIONS_ROUTE}`}
            className="d-flex  itemnav-link flex-fill justify-content-center align-items-center"
            activeClassName="itemnav-active"
          >
            <NotificationIcon
              transform="scale(0.66)"
              className={cx({
                "topbar-icon":
                  props.location.pathname ===
                  `${match.url}${NOTIFICATIONS_ROUTE}`,
              })}
            />
            <div className="ml-2">Notifications</div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
