import Api from "src/helpers/request";
import { toCamelCaseKeys } from "src/helpers/common";
export const getPocDetailsEndpoint = "/pocs";
export const getAdminPocsEndpoint = "/pocs";


/* API to create a new Poc */
export const createAdminPoc = (data) => {
    return Api.call(
        { url: `${getAdminPocsEndpoint}`, method: "POST", data },
        { showLoader: true }
    );
};

/* API to fetch labs list */
export const fetchAdminPocs = (metaConfig = { showLoader: true }) => {
    return Api.call(
        {
        url: `${getAdminPocsEndpoint}?pagination=false`,
        method: "GET",
        },
        metaConfig
    );
};

/* API to completely update/replace the state of a given poc*/
export const saveAdminPoc = (data, pocId) => {
    return Api.call(
      { url: `${getAdminPocsEndpoint}/${pocId}`, method: "PUT", data },
      { showLoader: true }
    );
};
  
/* API  to partially update the state of a given poc*/
export const partiallyUpdatePoc = (data, pocId) => {
    return Api.call(
      { url: `${getAdminPocsEndpoint}/${pocId}`, method: "PATCH", data },
      { showLoader: true }
    );
};


export const getPocDetails = (labId) => {
    return Api.call(
      { url: `${getPocDetailsEndpoint}/${labId}`, method: "GET" },
      { showLoader: true }
    );
  };

export const getDeployedConsoles = (pocID) => {
  // Simulate an API call
  return Promise.resolve(0); // Currently always returns 0
};

// /* API to enable/disable lab */
// export const enableLab = (data, pocId) => {
//     return Api.call(
//       { url: `${getAdminPocsEndpoint}/${pocId}`, method: "PATCH", data },
//       { showLoader: true }
//     );
// };
  