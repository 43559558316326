import { GET_SETTINGS } from "./reducer";


// export const getSettings = (metaConfig = { showLoader: true }) => async (
//     dispatch
// ) => {
//     let data = await fetchAdminPocs(metaConfig);
//     if (data) {
//         let labs = mapAdminPocs(data);
//         dispatch({ type: GET_SETTINGS, payload: labs });
//     }
// };

export const getSettings = () =>
    (dispatch) => {
        let data = { pod_start: 11, pod_end: 70 }
        dispatch({ type: GET_SETTINGS, payload: data });
    };
