import produce from "immer";

export const GET_POCS_SUCCESS = "GET_POCS_SUCCESS";

export interface IPocs {
  list: Array<IPoc>;
}

export interface IPoc {
  pocId: number;
  oppId: number;
  company: string;
  status: string;
}

// export interface IPOCs {
//   poc_id: number;
//   created_on: Date;
//   updated_on: Date;
//   lab_provider_type: string;
//   status: string;
//   company: string;
//   account_ae: string;
//   opp_id: string;
//   requested_start: string | null;
//   actual_start: string | null;
//   end_date: string | null;
//   pod_number: number;
//   test_plan: string | null;
//   theatre: string;
//   stage: string;
//   deployed_by: string;
//   account_se: string;
//   assigned_users: any[];
//   assigned_groups: any[];
//   assigned_companies: any[];
//   vc_for_users: any[];
//   vc_for_groups: any[];
//   vc_for_companies: any[];
// }

const initialState: IPocs = {
  list: [],
};

export default (state = initialState, action): IPocs =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_POCS_SUCCESS:
        draft.list = action.payload;
        break;

      default:
        break;
    }
  });
