import { mapAdminPocs } from "src/helpers/pocs/index";
import { GET_POCS_SUCCESS } from "./reducer";
import { fetchAdminPocs } from "src/api/poc";

export const getPocs = (metaConfig = { showLoader: true }) => async (
  dispatch
) => {
  let data = await fetchAdminPocs(metaConfig);
  if (data) {
    let pocs = mapAdminPocs(data);
    dispatch({ type: GET_POCS_SUCCESS, payload: pocs });
  }
};
