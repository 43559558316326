import React, { useState, useEffect } from "react";
import "./ManagePoc.scss";
import labAccess from "src/assets/images/lab-access.svg";
import FormLayout from "src/components/shared/FormLayout";
import { Form, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import DragAndDrop from "src/components/ui/DragAndDrop";
import * as Yup from "yup";
import InputErrorMessage from "src/components/shared/InputErrorMessage";
import RequiredFieldSymbol from "src/components/ui/RequiredFieldSymbol";
import { useDispatch, useSelector } from "react-redux";
import {
  createAdminPoc,
  getPocDetails,
  saveAdminPoc,
  getDeployedConsoles
} from "src/api/poc";
import InfoIcon from "src/assets/images/info-icon.svg";

import {
  mapPocDetails,
  getPayload,
  mapPodValues
} from "src/helpers/pocs/index";
import CustomTooltip from "src/components/ui/CustomTooltip";

import { ADMIN_ROUTE, ADMIN_POCS_ROUTE } from "src/constants/appRoutes";
import { toCamelCaseKeys } from "src/helpers/common";
import { useHistory, useParams, Link } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import { userTypesMap } from "src/constants/users";
import { RootState } from "src/state/rootReducer";
import { IPoc } from "src/state/pocs/reducer";
import { getPocs } from "src/state/pocs/actions";
import Select from "react-select";
import Templates from "src/containers/admin/labs/ManageLab/Templates";
import DTPicker from "react-datepicker";
import { differenceInDays } from "date-fns"; // To calculate the difference in days
import { usersAction } from "src/state/users/actions";
import { ISettings } from "src/state/settings/reducer";


type ManagePocProps = {};

const MAX_IMG_SIZE = 1024 * 1024 * 3;

const newPoc = {
  pocId: "",
  status: "New",
  deployedBy: "",
  company: "",
  accountSe: "",
  accountAe: "",
  oppId: "",
  labProviderType: "VSA",
  requestedStart: "",
  actualStart: "",
  endDate: "",
  podNumber: -1,
  testPlan: "",
  theatre: "",
  stage: "",
  accessList: [] as any,
  voucherConfigList: [] as any,
};

const defaultBreadcrumb = {
  id: "",
  name: "",
  type: "Folder",
};
const theatreDropdownList = [{
  id: 1,
  name: 'AMER'
},
{
  id: 2,
  name: 'LATAM'
},
{
  id: 3,
  name: 'EMEA'
},
{
  id: 4,
  name: 'APJ'
},
{
  id: 5,
  name: 'WW/Other'
}]
const stageDropdownList = [{
  id: 1,
  name: '1 - Qualifying'
},
{
  id: 2,
  name: '2 - Validating Solution'
},
{
  id: 3,
  name: '3 - Proposing'
},
{
  id: 4,
  name: '4 - Negotiating'
},
{
  id: 5,
  name: '8 - Closed/Won'
},
{
  id: 6,
  name: '8 - Closed/Disqualified'
},]

const ManagePoc: React.FC<ManagePocProps> = () => {
  const history = useHistory();
  const { pocId } = useParams();
  const dispatch = useDispatch();
  const [pocDetails, setPocDetails] = useState(newPoc);
  const [podValues, setPodValues] = useState([]);
  const [status, setStatus] = useState(pocDetails.status);
  const [showPocPage, setShowPocPage] = useState(false);
  const [daysActive, setDaysActive] = useState(0);
  const pocs: IPoc[] = useSelector((state: RootState) => state.pocs.list);
  const user = useSelector((state: RootState) => state.user)
  const [sfdcId, setSfdcId] = useState("");
  const [deployedConsoles, setDeployedConsoles] = useState(0)

  const presentations: Array<any> = useSelector(
    (state: RootState) => state.presentationList.list
  );
  const corporateUserList: Array<any> = useSelector(
    (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));
  const settings: ISettings = useSelector((state: RootState) => state.settings);

  //validations necessary for the form fields
  const validationSchema = Yup.object({
    company: Yup.string().required("Company name is required"),
    oppId: Yup.string().required("Oppurtunity Id is required"),
    accountSe: Yup.string().required('Account SE is required'),
    accountAe: Yup.string().required("Account AE is required"),
    theatre: Yup.string().required("Theatre is required"),
    stage: Yup.string().required("Stage is required"),
    podNumber: Yup.string().required("POD Number is required"),
    requestedStart: Yup.string().required("Requested Start Date is required"),
  });

  // const handleSfdcIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fullUrl = event.target.value;
  //   const regex = /\/([^\/]+)$/;
  //   const match = fullUrl.match(regex);
  //   setSfdcId(match ? match[1] : ""); // Extract sfdcId or set to empty string
  //   setPocDetails({ ...pocDetails, oppId: fullUrl }); // Update poc with full URL
  // };

  useEffect(() => {
    // Fetch deployed consoles count when component loads or pocID changes
    const fetchDeployedConsoles = async () => {
      const count = await getDeployedConsoles(pocId);
      setDeployedConsoles(count);
    };
    const fetchPocDetails = async () => {
      if (pocId) {
        const data = await getPocDetails(pocId);
        if (data) {
          const pocDetails = toCamelCaseKeys(data);
          if (pocDetails.actualStart) {
            const startDate = new Date(pocDetails.actualStart);
            const currentDate = new Date();
            setDaysActive(differenceInDays(currentDate, startDate));
          }
          let mappedPocDetails = {
            ...mapPocDetails(
              pocDetails,
            ),
            status: pocDetails.status || "",
          };
          setPocDetails(mappedPocDetails);
          setShowPocPage(true);
        }
      }
    };

    fetchPocDetails();
    fetchDeployedConsoles();
    // dispatch(usersAction());
    // dispatch(getGroups());
    if (pocs.length === 0) {
      dispatch(getPocs());
    }
    if (corporateUserList.length === 0) {
      dispatch(usersAction());
    }
  }, []);

  //On Referesh of create poc page // get poc data for pod number filteration
  useEffect(() => {
    if (pocs.length != 0) {
      const getPodValues = async () => {
        let pod_values = await mapPodValues(settings, pocs);
        setPodValues(pod_values)
        newPoc.podNumber = pod_values[0];
        setShowPocPage(true)
      }
      getPodValues()
    }
  }, [pocs])

  const onSubmitHandle = async (poc, { setSubmitting }) => {
    setSubmitting(true);
    poc.deployedBy = user.id
    const payload = getPayload(poc);
    let response;

    if (pocId) {
      response = await saveAdminPoc(payload, pocId);
    } else {
      response = await createAdminPoc(payload);
    }

    setSubmitting(false);
    if (!!response && !response.isAxiosError) {
      history.push(`${ADMIN_ROUTE}${ADMIN_POCS_ROUTE}`);
    }
  };

  const scrollToError = () => {
    setTimeout(function () {
      const errorElementList = document.getElementsByClassName("error-message");
      if (errorElementList.length) {
        errorElementList[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 200);
  };

  return showPocPage ? (
    <div className="pocs-manage-section d-flex flex-column">
      <div className={`pocs-list-section-header mb-3`}>
        <b>
          <Link className="group-user-header" to="/admin/pocs">
            POCs
          </Link>
        </b>
        <img
          height="15"
          width="15"
          src={rightArrow}
          alt="InfoIcon"
          className="mr-1 ml-1"
        />
        <span className="h4">{pocId ? `Edit POC` : `Create POC`}</span>
      </div>
      <Formik
        initialValues={pocDetails}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandle}
        enableReinitialize
      >
        {(formik) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormLayout headerImage={labAccess} headerTitle="POC Configuration">
              <Form className="py-4 px-5">
                <div className="poc-container">
                  <div className="">

                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label>
                        Company
                        <CustomTooltip text="Enter name of company the SE is requesting POC for">
                          <img
                            height="15"
                            width="15"
                            src={InfoIcon}
                            alt="InfoIcon"
                            className="ml-1"
                          />
                        </CustomTooltip>
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Form.Control
                          type="text"
                          id="company"
                          {...formik.getFieldProps("company")}
                        />
                        {formik.touched.company && formik.errors.company && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={formik.errors.company || ""}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label>Opportunity ID <RequiredFieldSymbol /></Form.Label>
                      <div>
                        <Form.Control
                          type="text"
                          id="oppId"
                          {...formik.getFieldProps("oppId")}
                        // value={sfdcId} // Display only the sfdcId part
                        // onChange={handleSfdcIdChange}
                        />
                        {formik.touched.oppId && formik.errors.oppId && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={formik.errors.oppId || ""}
                          />
                        )}
                      </div>
                      {/* <Form.Text className="text-muted">
                        <a href={formik.values.oppId} target="_blank" rel="noopener noreferrer">
                          {sfdcId}
                        </a>
                      </Form.Text> */}
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label >
                        Account SE
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Select
                          // isDisabled={isEditMode}
                          id='accountSe'
                          className="select-nameEmail"
                          size={5}
                          placeholder="Search name or email"
                          defaultValue={{
                            value: formik.values.accountSe,
                            label: formik.values.accountSe,
                          }}
                          isClearable={true}
                          onChange={(data) => {
                            formik.setFieldTouched("accountSe");
                            formik.setFieldValue(
                              "accountSe",
                              data && data.value ? data.value : ""
                            );
                          }}
                          options={corporateUserList.map((resource) => {
                            return {
                              value: resource.id,
                              label: `${resource.name}, ${resource.email}`

                            };
                          })}
                        />
                        {formik.touched.accountSe && formik.errors.accountSe && (
                          <InputErrorMessage
                            className={"error-message"}
                            errorMessage={formik.errors.accountSe || ""}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label>Account AE <RequiredFieldSymbol /></Form.Label>
                      <div>
                        <Form.Control
                          type="text"
                          id="accountAe"
                          {...formik.getFieldProps("accountAe")}
                        />
                        {formik.touched.accountAe && formik.errors.accountAe && (
                          <InputErrorMessage
                            className={"error-message ml-1"}
                            errorMessage={formik.errors.accountAe || ""}
                          />
                        )}
                      </div>

                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label >
                        Theatre
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Form.Control
                          type="select"
                          as="select"
                          id="theatre"
                          {...formik.getFieldProps("theatre")}
                          isInvalid={!!(formik.touched.theatre && formik.errors.theatre)}
                        >
                          {[
                            <option value="">Select</option>,
                            ...theatreDropdownList.map((theatre) => (
                              <option value={theatre.name}>
                                {(theatre.name)}
                              </option>
                            )),
                          ]}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <InputErrorMessage errorMessage={formik.errors.theatre || ""} />
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label >
                        Stage
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Form.Control
                          type="select"
                          as="select"
                          id="stage"
                          {...formik.getFieldProps("stage")}
                          isInvalid={!!(formik.touched.stage && formik.errors.stage)}
                        >
                          {[
                            <option value="">Select</option>,
                            ...stageDropdownList.map((stage) => (
                              <option value={stage.name}>
                                {(stage.name)}
                              </option>
                            )),
                          ]}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <InputErrorMessage errorMessage={formik.errors.stage || ""} />
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label >
                        POD Number
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <Form.Control
                          type="select"
                          as="select"
                          id="podNumber"
                          {...formik.getFieldProps("podNumber")}
                          isInvalid={!!(formik.touched.podNumber && formik.errors.podNumber)}
                        >
                          {[
                            <option value="">Select</option>,
                            ...podValues.map((pod_num) => (
                              <option value={pod_num}>
                                {`POD - ${pod_num}`}
                              </option>
                            )),
                          ]}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          <InputErrorMessage errorMessage={formik.errors.podNumber || ""} />
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group className="lab-container-inner align-items-center mb-4">
                      <Form.Label>Requested Start Date <RequiredFieldSymbol /></Form.Label>
                      <div>
                        <Field name="requestedStart">
                          {({ field }) => (
                            <DTPicker
                              {...field}
                              selected={formik.values.requestedStart ? new Date(formik.values.requestedStart) : null}
                              onChange={(date) =>
                                formik.setFieldValue("requestedStart", date.toISOString())
                              }
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="yyyy-MM-dd'T'HH:mm:ss'Z'"
                              className="date-picker-container form-control"
                              placeholderText="Enter Requested Start Date"
                            />
                          )}
                        </Field>
                        {formik.touched.requestedStart && formik.errors.requestedStart && (
                          <InputErrorMessage
                            className={"error-message"}
                            errorMessage={formik.errors.requestedStart || ""}
                          />
                        )}
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="lab-container-inner align-items-center">
                      <Form.Label>Theatre</Form.Label>
                      <Select
                        isMulti
                        classNamePrefix="react-select"
                        placeholder="Select"
                        //isDisabled={!formik.values.labTemplate || !formik.values.labConsoleAccessVM}
                        value={formik.values.theatre}
                        options={theatreDropdownList.map((theater) => ({
                          label: theater.name,
                          value: theater.name
                        }))}
                        onChange={(selectedTheatre) => {
                          formik.setFieldTouched("theater");
                          formik.setFieldValue("theater", selectedTheatre || []);
                        }}
                      />
                    </Form.Group> */}
                    {/* <Form.Group className="lab-container-inner mt-3">
                      <Form.Label>
                        Theatre
                        <RequiredFieldSymbol />
                      </Form.Label>
                      <div>
                        <select
                          className="form-control"
                          defaultValue={pocDetails.theatre}

                        >
                          <option
                            value="SKYTAP"
                            selected={
                              formik.values.labProviderType === "SKYTAP"
                            }
                          >
                            SkyTap
                          </option>
                          <option
                            value="VCD"
                            selected={formik.values.labProviderType === "VCD"}
                          >
                            CSC SOL vCD
                          </option>
                          <option
                            value="VRA"
                            selected={formik.values.labProviderType === "VRA"}
                          >
                            CSC SOL vRA
                          </option>
                        </select>
                      </div>
                    </Form.Group> */}
                    {/* <Form.Group>
                      <Form.Label>POD</Form.Label>
                      <Form.Control
                        type="number"
                        {...formik.getFieldProps("podNumber")}
                        value={formik.values.podNumber ? parseInt(formik.values.podNumber, 10) : ""}
                        onChange={(e) => {
                          const value = e.target.value;
                          formik.setFieldValue("podNumber", value ? String(value) : "");
                        }}
                      />
                    </Form.Group>*/}
                    {/* <Form.Group> 
                      <Form.Label>Deployed Consoles</Form.Label>
                      <Form.Control
                        type="number"
                        readOnly
                        value={deployedConsoles}
                        placeholder="Number of consoles deployed"
                        className="form-control"
                        disabled={!formik.values.actualStart}
                      />
                    </Form.Group> */}
                  </div>
                </div>
              </Form>
            </FormLayout>
            <div className="d-flex justify-content-end mt-2">
              {/* <div className="btn btn-outline-primary btn-sm footer-btn">
                <Link
                  className="pure-link-decoration-none"
                  to={`${ADMIN_ROUTE}${ADMIN_POCS_ROUTE}`}
                >
                  Cancel
                </Link>
              </div> */}
              <Button
                type="submit"
                size="sm"
                variant="outline-secondary"
                className="footer-btn lab-cancel color-primary border-primary"
                onClick={() => {
                  history.push(`${ADMIN_ROUTE}${ADMIN_POCS_ROUTE}`);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                size="sm"
                variant="primary"
                className="ml-2 footer-btn"
                onClick={() => {
                  formik.handleSubmit();
                  scrollToError();
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {/* : ''} */}
    </div>
  ) : null;
};

export default ManagePoc;
